.filter_wrap{
    display: flex;
    justify-content:  center;
    margin-bottom: 24px;
    gap: 10px;
    button{
        padding: 10px 16px;
        border-radius: 12px;
        cursor: pointer;
        font-size: 12px;
        border: 1px solid #d6d6d6;
        color: #68686C;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
        &.activeCat{
            background-color: #ff4729;
            color: #fff;
        
        }
    }
}
@media(max-width: 992px){
    .filter_wrap{
       overflow-x: auto; 
       justify-content: flex-start;
       padding-left: 16px;
       &::-webkit-scrollbar{
        display: none;
       } 
       button{
        padding: 8px 14px;
        display: block;
        white-space: nowrap;
       }
    }
}