.header{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
    padding: 0px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    background-color: #fff;
   
    .header_side{
        display: flex;
        align-items: center;
        .logoHeader{
            width: 80px;
            margin-right: 2px;
            top: 0;
        }
        a{
            font-weight: 700;
            color: #828282;
            margin-left: 16px;
            display: inline-block;
            img{
                width: 22px;
                position: relative;
                top: 5px;
            }
        }
       
    }
}
.subHeader{
    max-width: 1000px;
    width: 100%;
    margin: 32px auto;
    border-radius: 18px;
    overflow: hidden;
    background-color: cover;
    background-position: center top;
    background-image: url(../../../public/img/fon.png);
    position: relative;
    height: 170px;
    z-index: 1;
    &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        background-color: rgba(115, 115, 115, 0.3);
        width: 100%;
        height: 100%;
        z-index: 2;
    }
    .mnu{
        position: absolute;
        top: 16px;
        left: 16px;
        z-index: 10;
        .mnu_link{
            color: #ff4729;
            background-color: #fff;
            padding: 12px 22px;
            border: 1.4px solid #fff;
            border-radius: 14px;
            font-weight: 700;
            margin-right: 12px;
        }
    }
}
@media(max-width: 992px){
    .header{
        .header_side{
            width: 100%;
            justify-content: space-between;
            .logoHeader{
                width: 65px;
            }
        }
    }
}