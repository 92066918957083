.popup{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0, .3);
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 333;
    .popup_product{
        background-color: #fff;
        max-width: 700px;
        width: 100%;
        overflow-y: hidden;
        overflow: auto;
        height: 550px;
        border-radius: 16px;
        &:hover{
            .product_img{
                height: 360px;
            }
        }
        .product_img{
            display: block;
            width: 100%;
            height: 200px;
            object-fit: cover;
            transition: height .3s ease;
        }
        .product_title{
            padding: 10px 16px;
            font-size: 20px;
            font-weight: 700;
        }
        .product_description{
            padding: 10px 16px;
            font-size: 14px;
            font-weight: 700;
            color: #68686C;
        }
        .product_bottom{
            padding: 10px 16px;
            font-weight: 700;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .product_bottom_func{
                display: flex;
                align-items: center;
                gap: 18px;
            }
        }
        .product_bottom_price{
            font-size: 24px;
        }
        .addToCart{
            background-color: #ff4729;
            text-align: center;
            color: #fff;
            font-weight: 700;
            font-size: 24px;
            padding: 16px 40px;
            border: none;
            border-radius: 18px;
            cursor: pointer;
            transition: opacity .3s ease;
            &:hover{
                opacity: 0.7;
            }
        }
    }
}
@media(max-width: 992px){
    .popup{
        .popup_product{
            .addToCart{
                font-size: 18px;
                padding: 11px 28px;
            }
        }
    }
}