@import "../../assets/css/media.scss";

.vlog_box{
    max-width: 1020px;
    width: 100%;
    margin: 120px auto;
    h2{
        margin-bottom: 38px;
        @include media-mob{
            text-align: center;
        }
    }
}
.vlog_carousel{
    width: 100%;
    position: relative;
   
    .arrow{
        position: absolute;
        top: -70px;
        background: linear-gradient(176deg, rgb(2, 0, 36) 0%, rgb(255, 71, 41) 0%, rgb(255, 207, 0) 100%);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all .3s ease;
        @include media-mob{
            display: none;
        }
        &:hover{
            opacity: 0.7;
        }
        img{
            width: 30px;
        }
    }
    .prev{
        right: 70px;
    }
    .next{
        right: 0;
    }
    .vlog__Item{
        max-width: 320px;
        width: 100%;
        background-color: #fff;
        padding: 16px 8px;
        border-radius: 12px;
        text-align: center;
        margin: 0 auto;
        img{
            width: 100%;
            height: 202px;
            object-fit: cover;
            border-radius: 10px;
        }
        p{
            font-size: 20px;
            font-weight: 800;
            padding: 16px 0;
        }
        .moreBtn{
            background: linear-gradient(176deg, rgb(2, 0, 36) 0%, rgb(255, 71, 41) 0%, rgb(255, 207, 0) 100%);
            padding: 16px 30px;
            color: #fff;
            border-radius: 16px;
            display: inline-block;
            font-weight: 800;
        }
    }
}
