.footer{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
    padding: 12px;
    background-color: #fff;
    width: 100%;
    margin: 0 auto;
    padding: 24px 12px;
    margin-top: 170px;
    &_inner{
        max-width: 1100px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        a{
            margin-left: 10px;
            svg{
                fill: #ff4729;
                color: #fff;
                transition: all .3s ease;
                &:hover{
                    opacity: 0.7;
                    transform: scale(1.1);
                }
            }
        }
    }
}
@media(max-width: 992px){
    .footer{
        font-size: 12px;
        margin-top: 50px;
    }
}