.fixedCart{
    width: 70px;
    height: 70px;
    position: fixed;
    right: 12px;
    top: 140px;
    z-index: 999;
    border-radius: 50%;
    border: 1px solid #fcc1c1;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .count{
        background-color: #ff4729;
        width: 18px;
        height: 18px;
        line-height: 18px;
        font-size: 12px;
        color: #fff;
        position: absolute;
        top: 0;
        right: 0;
        text-align: center;
        border-radius: 50%;
    }
    img{
        display: block;
        width: 50%;
    }
}
