.tile{
    width: 100%;
    max-width: 320px;
    height: 220px;
    border-radius: 16px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content:  center;
    color: #fff;
    background: linear-gradient(176deg, rgba(2,0,36,1) 0%, rgba(255,71,41,1) 0%, rgba(255,207,0,1) 100%);
    transition: all .3s ease;
    position: relative;
    overflow: hidden;
    &::after{
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background-color: rgba(0,0,0, .5);
        filter: blur(4px);
        z-index: 5;
        transition: all .3s ease;

    }
    &:hover{
        transform: scale(1.06);
        &::after{
            opacity: 1;
        }
        .title{
            opacity: 1;
        }
    }
    .imgTile{
        position: absolute;
        width: auto;
        height: 170px;
        object-fit: cover;

    }
    .title{
        position: relative;
        font-weight: 700;
        font-size: 22px;
        text-align: center;
        z-index: 10;
        opacity: 0;
        transition: all .3s ease;
    }
}

@media(max-width: 992px){
    .tile{
        flex-wrap: wrap;
        justify-content: center;
        width: 47%;
        height: 170px;
        .imgTile{
            height: 130px;
        }
    }
}