

* {
  padding: 0;
  margin: 0;
  list-style: none;
  outline: none;
  font-family: "Comfortaa", sans-serif;
  
  box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
  font-family: "Comfortaa", sans-serif;
  background-color: #efefef;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

a,
span,
p,
b,
h1,
h2,
h3,
h4,
h5 {
  color: $black;
}

h1 {
  font-size: 48px;
}
@media(max-width: 992px){
  h1{
    font-size: 36px;
  }
}
h2 {
  font-weight: 600;
  font-size: 28px;
  line-height: 30px;
}

a {
  text-decoration: none;
}

@mixin noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

