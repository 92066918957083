.selectBox{
    max-width: 380px;
    width: 100%;
    position: relative;
    padding-bottom: 24px;
    font-size: 14px;
    .cefSelect{
        width: 100%;
        
        input{
            width: 100%;
            border: none;
            border: 1px solid #d3d3d3;
            padding: 5px 10px;
            font-size: 16px;
        }
        .selectItems{
            background-color: #fff;
            position: absolute;
            top: 30px;
            left: 0;
            width: 100%;
            border: 1px solid #d3d3d3;
            padding: 0;
            margin-bottom: 26px;
            max-height: 164px;
            overflow: auto;
            z-index: 50;
            font-size: 14px;
            .selectItems_el{
                line-height: 25px;
                padding: 5px 13px;
                &:hover{
                    background-color: #eee;
                    cursor: pointer;
                }
            }
            
        }
    }
}
.np-department-select{
    margin-bottom: 16px;
    .react-select__control{
        border: none;
        border: 1px solid #d3d3d3;
        border-radius: 10px;
    }
}